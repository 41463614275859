import React from 'react'
import { Script } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import theme from './src/config/theme'
import Layout from './src/components/atoms/Layout'
import { GlobalStyle } from './src/styles/global-style'
import { HelmetProvider } from 'react-helmet-async'
import './src/config/icons'

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    <Script id="___loader_script">
      {`
      document.getElementById("___loader").style.backdropFilter = "blur(8px) opacity(0)"
      document.getElementById("___loader").style.backgroundColor = "rgba(242, 242, 242, 0.1)"
      setTimeout(() => {
        document.getElementById("___loader").remove()
        document.getElementById("___loader_script").remove()
      }, 800)
      `}
    </Script>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>{element}</Layout>
    </ThemeProvider>
  </HelmetProvider>
)

export const wrapPageElement = ({ element }) => (
  <HelmetProvider>
    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
      integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
      crossorigin="" />
    <link type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>{element}</Layout>
    </ThemeProvider>
  </HelmetProvider>
)
