const theme = {
  blue: '#0049ee',
  black: '#000',
  white: '#fff',
  green: '#5fc34b',
  skyBlue: '#7fa4f6',
  paleGrey: '#f8fafd',
  paleBlue: '#eaf2ff',
  otherBlue: '#5c8bf4',
  mediumBlue: '#cedeff',
  lightOrange: '#ff9900',
  transparent: 'transparent',
  grey: '#adadad',
  brownishGrey: '#707070',
  brightOrange: '#ff6600',
  veryLightOrange: '#fff9ef'
}

export default theme
