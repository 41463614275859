/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { includes } from 'lodash'

import { wrapRootElement as wrapRoot, wrapPageElement as wrapPage } from './root-wrapper'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  const { pathname, href } = location

  if (includes(href, '#')) {
    return true
  }
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = []

  // if the new route is not part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) === -1) {
    window.scrollTo(0, 0)
  }

  return false
}

export const wrapPageElement = wrapPage

export const wrapRootElement = wrapRoot
